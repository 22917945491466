[data-tippy-root]
  max-width: calc(100vw - 10px)

.tippy-box
  +preset(3)
  position: relative
  background-color: var(--surface-white)
  color: var(--on-surface-dark)
  box-shadow: var(--surface-shadow)
  white-space: normal
  outline: 0
  &[data-placement^="top"]
    > .tippy-arrow
      bottom: 0
      &:before
        bottom: -7px
        left: 0
        border-width: 8px 8px 0
        border-top-color: initial
  &[data-placement^="bottom"]
    > .tippy-arrow
      top: 0
      &:before
        top: -7px
        left: 0
        border-width: 0 8px 8px
        border-bottom-color: initial

  &[data-placement^="left"]
    > .tippy-arrow
      right: 0
      &:before
        border-width: 8px 0 8px 8px
        border-left-color: initial
        right: -7px

  &[data-placement^="right"]
    > .tippy-arrow
      left: 0
      &:before
        left: -7px
        border-width: 8px 8px 8px 0
        border-right-color: initial

.tippy-arrow
  width: 16px
  height: 16px
  color: var(--surface-white)
  &:before
    content: ""
    position: absolute
    border-color: transparent
    border-style: solid

.tippy-content
  position: relative
  padding: 8px
  z-index: 1

.tippy-box[data-theme~="html"] .tippy-content
  padding: 24px

.tippy-box[data-theme~="ghost"]
  background: none
  box-shadow: none
  .tippy-content
    padding: 0
  .tippy-arrow
    display: none