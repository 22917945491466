import { Component, Host, h, Prop, State, Element, Event, EventEmitter, forceUpdate } from '@stencil/core';

@Component({
  tag: 'x-timepicker',
  styleUrl: 'x-timepicker.sass',
})
export class XTimepicker {
  @Element() el: HTMLElement;

  @Prop() from: string;
  @Prop() to: string;
  @Prop() button: any = 'Search';
  @Prop() format: string = 'h:m:s';

  @State() value: any = {};

  @Event({ bubbles: false }) update: EventEmitter;

  inputFrom;
  inputTo;

  private parseValue = () => {
    this.value = ['from', 'to'].reduce((result, key) => {
      result[key] = this[key].split(':').map(v => parseInt(v, 10));
      return result;
    }, {});
  };

  private renderValue = key => {
    return this.value[key].map(val => `0${val}`.slice(-2)).join(':');
  };

  private parseInputValue = ({ value }) => {
    if (!value || !/^[0-9]{1,2}(\:[0-9]{1,2}){0,2}$/.test(value)) {
      return;
    }

    const valueArray = value.split(':');

    return this.format
      .split(':')
      .fill('')
      .map((...[, index]) => parseInt(valueArray[index] || 0));
  };

  private handleChangeTime = () => {
    const fromInput = this.inputFrom.querySelector('input');
    const toInput = this.inputTo.querySelector('input');
    const fromValue = this.parseInputValue(fromInput);
    const toValue = this.parseInputValue(toInput);

    if (fromValue && fromValue.toString() !== this.value.from.toString()) {
      // update model if valid
      this.value.from = fromValue;
    } else {
      // reset to model value if invalid
      fromInput.value = this.renderValue('from');
    }

    if (toValue && toValue.toString() !== this.value.to.toString()) {
      // update model if valid
      this.value.to = toValue;
    } else {
      // reset to model value if invalid
      toInput.value = this.renderValue('to');
    }

    forceUpdate(this.el);
  };

  private handleSelectTime = (e, type) => {
    this.value[type] = e.detail;
    forceUpdate(this.el);
  };

  private apply = () => {
    this.update.emit(this.value);
  };

  connectedCallback() {
    this.parseValue();
  }

  render() {
    return (
      <Host class="x-timepicker">
        <div class="x-timepicker__range">
          <div class="x-timepicker__period">
            <div class="x-timepicker__period-input">
              <x-input mask="time" align="center" value={this.renderValue('from')} onChange={this.handleChangeTime} ref={el => (this.inputFrom = el)}></x-input>
            </div>
            <div class="x-timepicker__period-combination">
              <x-time-combination value={this.renderValue('from')} onUpdate={e => this.handleSelectTime(e, 'from')} format={this.format}></x-time-combination>
            </div>
          </div>
          <div class="x-timepicker__divider"></div>
          <div class="x-timepicker__period">
            <div class="x-timepicker__period-input">
              <x-input mask="time" align="center" value={this.renderValue('to')} onChange={this.handleChangeTime} ref={el => (this.inputTo = el)}></x-input>
            </div>
            <div class="x-timepicker__period-combination">
              <x-time-combination value={this.renderValue('to')} onUpdate={e => this.handleSelectTime(e, 'to')} format={this.format}></x-time-combination>
            </div>
          </div>
        </div>
        {this.button !== null && (
          <div class="x-timepicker__apply">
            <x-button type="button" variant="outlined" size="lg" stretch onClick={this.apply}>
              {this.button}
            </x-button>
          </div>
        )}
      </Host>
    );
  }
}
