.x-timepicker
  &__range
    display: flex
  &__period
    &:not(:only-child)
      width: calc(50% - 6px)
    &-input
      margin-bottom: 14px
    &-combination
      display: flex
      justify-content: center
  &__divider
    width: 4px
    height: 1px
    background-color: var(--divider-dark-grey)
    margin: 20px 4px 0
  &__apply
    margin-top: 13px
