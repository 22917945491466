.x-button
  +preset(3)
  font-weight: 600
  display: inline-block
  vertical-align: top
  &__body
    display: flex
    align-items: center
    justify-content: center
    border: 1px solid rgba(255, 255, 255, 0)
    padding: 0 15px
    white-space: nowrap
    border-radius: var(--base-radius)
    &-icon
      font-size: 0
      line-height: 0
      margin-right: 10px
    &-value
      flex: 1 1 auto
      text-align: center

    &.-state-disabled
      cursor: not-allowed

  &[stretch]
    display: block
    .x-button__body
      width: 100%
  &[shape]
    .x-button__body
      padding: 0
  &[shape="circle"]
    .x-button__body
      border-radius: 50%

  &[size="sm"]
    .x-button__body
      height: 28px
  &[size="sm"][shape]
    .x-button__body
      width: 28px
  &[size="md"]
    .x-button__body
      height: 32px
  &[size="md"][shape]
    .x-button__body
      width: 32px
  &[size="lg"]
    .x-button__body
      height: 40px
  &[size="lg"][shape]
    .x-button__body
      width: 40px

  &[variant="contained"]
    .x-button__body
      color: var(--on-button-white)
      &.-color-primary
        background: var(--primary)
        &:hover
          background: var(--primary-hover)
      &.-color-red
        background: var(--button-static-red)
        &:hover
          background: var(--button-hover-red)
      &.-state-disabled
        background: var(--button-static-dark-grey) !important
  &[variant="outlined"]
    .x-button__body
      background: var(--button-static-white)
      border-color: var(--button-static-dark-grey)
      color: var(--on-button-dark)
      &-icon
        color: var(--icon-primary)
      &.-color-dark-grey
        color: var(--icon-dark-grey)
        &:hover
          color: var(--primary-hover)
      &:hover
        border-color: var(--button-hover-green)
        color: var(--on-button-hover-green)
        .x-button__body-icon
          color: var(--icon-hover-primary)
      &.-state-disabled
        color: var(--button-static-dark-grey)
        border-color: var(--button-static-dark-grey)
        background: var(--button-disabled)
