import { Component, Host, Prop, Fragment, Element, h } from '@stencil/core';
import clsx from 'clsx';

@Component({
  tag: 'x-button',
  styleUrl: 'x-button.sass',
})
export class XButton {
  @Element() el: HTMLElement;

  @Prop() href: string;
  @Prop() icon: string;
  @Prop() iconSize: string = '16';
  @Prop() rel: string;
  @Prop() shape: 'square' | 'circle';
  @Prop({ reflect: true }) size: 'sm' | 'md' | 'lg';
  @Prop({ reflect: true }) color: 'primary' | 'red' = 'primary';
  @Prop() slim: boolean = false;
  @Prop({ reflect: true }) stretch: boolean = false;
  @Prop() disabled: boolean = false;
  @Prop() target: string;
  @Prop() type: 'button' | 'submit' | 'reset' = 'submit';
  @Prop({ reflect: true }) variant: 'contained' | 'outlined';

  private getCommonProps = () => {
    return {
      class: clsx('x-button__body', `-color-${this.color}`, { '-state-disabled': this.disabled }),
    };
  };

  private hasChildren = () => {
    return Boolean(Array.from(this.el.childNodes).filter(child => child.nodeType !== Node.COMMENT_NODE).length);
  };

  private renderInner = () => {
    return (
      <Fragment>
        {!!this.icon && (
          <span class="x-button__body-icon">
            <x-icon glyph={this.icon} size={this.iconSize}></x-icon>
          </span>
        )}
        <span class="x-button__body-value" hidden={!this.hasChildren()}>
          <slot></slot>
        </span>
      </Fragment>
    );
  };

  private renderA = () => {
    return (
      <a href={this.href} target={this.target} rel={this.rel} {...this.getCommonProps()}>
        {this.renderInner()}
      </a>
    );
  };

  private renderButton = () => {
    return (
      <button type={this.type} disabled={this.disabled} {...this.getCommonProps()}>
        {this.renderInner()}
      </button>
    );
  };

  render() {
    return <Host class="x-button">{!!this.href ? this.renderA() : this.renderButton()}</Host>;
  }
}
