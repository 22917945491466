import { Component, Host, h, Prop, State, Event, EventEmitter, Element, Watch, Fragment } from '@stencil/core';
import IMask from 'imask';

@Component({
  tag: 'x-time-combination',
  styleUrl: 'x-time-combination.sass',
})
export class XTimeCombination {
  @Element() el: HTMLElement;

  @Prop() name: string;
  @Prop() value: any;
  @Prop() format: string = 'h:m:s';

  @State() innerValue: number[];

  @Event({ bubbles: false }) update: EventEmitter;

  format_array = this.format.split(':');

  input_h!: HTMLInputElement;
  input_m!: HTMLInputElement;
  input_s!: HTMLInputElement;

  mask_h;
  mask_m;
  mask_s;

  connectedCallback() {
    this.innerValue = this.value.split(':').map(int => parseInt(int, 10));
  }

  componentDidLoad() {
    this.format_array.forEach(entry => {
      this[`mask_${entry}`] = IMask(this[`input_${entry}`], {
        mask: IMask.MaskedRange,
        lazy: false,
        from: 0,
        to: entry === 'h' ? 23 : 59,
      });
    });
  }

  componentDidRender() {
    this.format_array.forEach(entry => {
      if (this[`mask_${entry}`]) {
        this[`mask_${entry}`].updateValue();
      }
    });
  }

  @Watch('value')
  handleValue() {
    this.innerValue = this.value.split(':').map(int => parseInt(int, 10));
  }

  @Watch('innerValue')
  emit(val) {
    this.update.emit(val);
  }

  private renderCounter = entry => {
    const index = this.format_array.indexOf(entry);

    const handleButton = e => {
      const direction = parseInt(e.currentTarget.dataset.direction, 10);
      const min = 0;
      const max = entry === 'h' ? 23 : 59;

      let nextValue = this.innerValue[index] + direction;

      if (nextValue < min) {
        nextValue = max;
      } else if (nextValue > max) {
        nextValue = min;
      }

      const valueCopy = this.innerValue.slice(0);
      valueCopy[index] = nextValue;
      this.innerValue = valueCopy;

      this[`mask_${entry}`].updateValue();
    };

    const handleInput = e => {
      const nextValue = parseInt(e.target.value, 10);

      if (isNaN(nextValue)) {
        e.target.value = ('0' + this.innerValue[index]).slice(-2);
      } else {
        const valueCopy = this.innerValue.slice(0);

        valueCopy[index] = nextValue;
        this.innerValue = valueCopy;
      }

      this[`mask_${entry}`].updateValue();
    };

    const renderValue = () => {
      return ('0' + this.value.split(':')[index]).slice(-2);
    };

    return (
      <div class="x-time-combination__counter">
        <button type="button" onClick={handleButton} data-direction="1" tabindex="-1">
          <x-icon glyph="calendar-up" size="16"></x-icon>
        </button>
        <input type="text" value={renderValue()} ref={el => (this[`input_${entry}`] = el as HTMLInputElement)} onChange={handleInput} tabindex="0" />
        <button type="button" onClick={handleButton} data-direction="-1" tabindex="-1">
          <x-icon glyph="calendar-down" size="16"></x-icon>
        </button>
      </div>
    );
  };

  render() {
    return (
      <Host class="x-time-combination">
        {this.format_array.map((entry, index) => (
          <Fragment>
            {index !== 0 && <div class="x-time-combination__delimiter">:</div>}
            {this.renderCounter(entry)}
          </Fragment>
        ))}
      </Host>
    );
  }
}
