import { Component, Host, Element, Prop, Listen, h } from '@stencil/core';
import tippy from 'tippy.js';
import axios from 'axios';
import { translate } from '../../utils';

const t = translate({
  en: {
    loading: 'Loading...',
  },
  ru: {
    loading: 'Загрузка...',
  },
});

@Component({
  tag: 'x-tooltip',
  styleUrl: 'x-tooltip.sass',
})
export class XTooltip {
  @Element() el: HTMLElement;

  @Prop() content;
  @Prop() placement;
  @Prop() interactive;
  @Prop() maxWidth;
  @Prop() offset;
  @Prop() trigger;
  @Prop() theme;
  @Prop() instance;
  @Prop() flip: boolean = true;

  @Prop() endpoint: string;

  tippyInstance;

  @Listen('close')
  close() {
    this.tippyInstance.hide();
  }

  componentDidLoad() {
    const target = this.el;
    const template = target.querySelector('template');
    const contentNode = target.querySelector('x-tooltip-content');

    const allowHTML = !this.content;
    let content;

    if (this.content) {
      content = this.content;
    } else if (this.endpoint) {
      content = t('loading');
    } else if (template) {
      content = template.innerHTML;
    } else if (contentNode) {
      content = contentNode;
    } else {
      content = '';
    }

    this.tippyInstance = tippy(target, {
      content: content,
      placement: this.placement,
      interactive: this.interactive !== undefined,
      maxWidth: typeof this.maxWidth === 'string' ? parseInt(this.maxWidth, 10) : this.maxWidth,
      offset: typeof this.offset === 'string' ? this.offset.split(',').map(str => parseInt(str, 10)) : this.offset,
      trigger: this.trigger,
      ignoreAttributes: true,
      allowHTML: allowHTML,
      theme: this.theme || (allowHTML ? 'html' : ''),
      duration: [0, 0],
      zIndex: 11000,
      appendTo: document.querySelector('x-app'),
      // showOnCreate: true,
      popperOptions: { modifiers: [{ name: 'flip', enabled: this.flip }] },
      onShow: instance => {
        if (!this.endpoint) {
          return;
        }

        axios
          .get(this.endpoint)
          .then(res => {
            instance.setContent(res.data);
          })
          .catch(err => {
            instance.setContent(err);
          });
      },
    });

    if (typeof this.instance === 'function') {
      this.instance(this.tippyInstance);
    }
  }

  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
