.x-time-combination
  display: flex
  align-items: center
  margin: auto
  &__counter
    button
      display: block
      border: none
      background: none
      color: var(--icon-dark-grey)
      &:hover
        color: var(--icon-primary)
    input
      +preset(3)
      width: 16px
      height: 18px
      background: none
      border: none
      color: var(--on-input-white-20)
      margin: 1px 0 4px
      text-align: center
  &__delimiter
    +preset(3)
    margin: -4px 7px 0
    color: var(--on-input-white-20)
